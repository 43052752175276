import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'
import dynamic from 'next/dynamic'

export default function JivoChat() {
  const {integrations: config} = useInitialData()
  const jivoChatId = config?.integrations?.jivoChatId
  if (!jivoChatId) return null

  const Chat = dynamic(() => import('./Chat'))
  return <Chat jivoChatId={jivoChatId} />
}
