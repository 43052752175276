import React, {Suspense} from 'react'
import NoSSR from '@components/NoSSR'

export default function ClientSuspense({children, fallback}) {
  return (
    <NoSSR>
      <Suspense fallback={fallback}>{children}</Suspense>
    </NoSSR>
  )
}
