import getPathname from '@helpers/router/getPathname'
import useForceLogin from '@hooks/useForceLogin'
import useRoles from '@hooks/useRoles'
import authRouteRegex from '@page-components/Auth/routeRegex'
import useInitialData from '@page-components/Layout/useInitialData'
import {useRouter} from 'next/router'

// NOTA: Este componente esta con errores fatales
export default function WithAuth() {
  const {website} = useInitialData()
  const forceLogin = useForceLogin
  const roles = useRoles()
  const router = useRouter()
  const pathname = getPathname(router)

  if (authRouteRegex.test(pathname) || pathname.startsWith('/errors')) {
    return null
  }

  if (website && website.authOnlyJustoUsers) {
    forceLogin()
    if (!roles.length) router.push('/errors/403')
  }

  return null
}
