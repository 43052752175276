import React, {ReactNode, Suspense} from 'react'

/**
 * @deprecated
 */
export default function SafeSuspense({
  children,
  fallback
}: {
  children: React.ReactNode
  fallback: ReactNode
}) {
  return <Suspense fallback={fallback}>{children}</Suspense>
}
