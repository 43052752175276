import useEffect from '@hooks/useEffect'
import registerLocales from '@i18n/formatNumber/registerLocales'
import numeral from 'numeral'

export default function SetLocale(props) {
  const {locale} = props
  useEffect(() => {
    registerLocales([locale])
    numeral.locale(locale)
    global.currentLocale = locale
    // dayjs locale is set from @helpers/dayjs to avoid racing conditions
    // with the lib plugins
  }, [locale])
  return null
}
