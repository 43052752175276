import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import doesCountryRequireCookies from '@helpers/websites/doesCountryRequireCookies'
import dynamic from 'next/dynamic'

export default function CookiesBanner({
  website
}: {
  website: WebsiteInitialData_CachedQuery['website']
}) {
  if (doesCountryRequireCookies(website.countryCode) === false) {
    return null
  }

  const CookiesBannerComp = dynamic(() => import('@components/CookiesBanner'))
  return <CookiesBannerComp website={website} />
}
