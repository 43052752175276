import {useEffect} from 'react'
import isScrapping from '@helpers/misc/isScrapping'
import useInitialData from '@page-components/Layout/useInitialData'

declare const window: {
  indigitall: any
}

export default function IndigitallWebPushIntegration() {
  const {integrations: config} = useInitialData()

  useEffect(() => {
    if (!isScrapping && config?.integrations?.indigitallWebPush) {
      const script = document.createElement('script')
      const url = `https://s3.amazonaws.com/orion-eat-app-files/orioneat-prod%2FPBaN4y65sqBHJndaR-sdk.min.js`
      script.src = url
      script.async = true
      script.onload = () => {
        window.indigitall.init({
          appKey: config.integrations.indigitallWebPush,
          urlDeviceApi: 'https://eu2.device-api.indigitall.com/v1',
          workerPath: '/indigitall/worker.js',
          requestLocation: true
        })
      }

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [config])

  return null
}
