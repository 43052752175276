export type PayTaxonomies = {
  paymentCompleted: {
    payStatus?: string
    payMethod?: string
    cardType?: string
    cardId?: string
    orderId?: string
    messageError?: string
    errorCode?: string
    isPayCancelledByUser?: boolean
    websiteURL?: string
    websiteId?: string
    storeId?: string
    countryCode?: string
    deviceType?: string
  }
  paymentSubmitted: {
    paymentMethod?: string
    cardType?: string
    cardId?: string
    tipAmount?: number
    couponAmount?: number
    couponCode?: string
    couponId?: string
    loyaltyAmount?: number
    orderId?: string
    menuId?: string
    messageError?: string
    errorCode?: string
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
  }
}

export const PAY_EVENTS = Object.freeze({
  paymentCompleted: 'payment-completed',
  paymentSubmitted: 'payment-submitted'
})
