import React from 'react'
import {useEffect} from 'react'
import isScrapping from '@helpers/misc/isScrapping'
import useInitialData from '@page-components/Layout/useInitialData'

export default function FidelityToolsIntegration() {
  const {integrations: config} = useInitialData() as any
  const validate = !isScrapping && config?.integrations?.fidelityToolsPush

  useEffect(() => {
    if (validate) {
      const script = document.createElement('script')
      const url = 'https://control.fidelitytools.net/public/scripts/fidelitytools-push.js'
      const css = document.createElement('link')
      const hrefStyle = 'https://control.fidelitytools.net/public/styles/fidelitytools-push.css'

      script.src = url
      script.async = true

      css.href = hrefStyle
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'async' does not exist on type 'HTMLLinkE... Remove this comment to see the full error message
      css.async = true

      document.body.appendChild(script)
      document.body.appendChild(css)

      return () => {
        document.body.removeChild(script)
        document.body.removeChild(css)
      }
    }
  }, [config])

  return validate ? (
    <div
      className="fidelitytools-push-btn"
      data-idRecurso={config.integrations.fidelityToolsPush}></div>
  ) : null
}
