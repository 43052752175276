import {useContext} from 'react'
import {Context} from '@providers/GlobalState'

export default function useUTMSessionStorageState() {
  const forceUpdate = useContext(Context)

  const setValue = (newValue, key) => {
    if (!newValue) return

    sessionStorage.setItem(key, JSON.stringify(newValue))
    forceUpdate()
  }

  return setValue
}
