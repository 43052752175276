import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'
import dynamic from 'next/dynamic'

export default function LogRocket() {
  const {integrations: config} = useInitialData()

  if (!config?.integrations?.logRocketId) return null

  const LogRocketIntegration = dynamic(() => import('./LogRocketIntegration'))

  return <LogRocketIntegration />
}
