import useEffect from '@hooks/useEffect'
import useSessionStorageState from '@hooks/useSessionStorageState'
import useUTMsessionStorageState from '@hooks/useUTMsessionStorageState'
import useWebsiteId from '@hooks/useWebsiteId'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'
import {useInitAnalytics} from '@page-components/Analytics'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'

import PageMeta from './PageMeta'
import UTMS from './UTMs'
import WebsiteData from './WebsiteData'

export default function Layout(props) {
  useInitAnalytics()
  const websiteId = useWebsiteId()
  // eslint-disable-next-line
  const [whatsappBusiness, setWhatsappBusiness] = useSessionStorageState('whatsapp-business')
  const setOrderSource = useSessionStorageState('orderSource')[1]
  const setWebview = useSessionStorageState('openByWebview')[1]
  const setMessengerReturnURL = useSessionStorageState('messengerReturnURL')[1]
  const setUTMByKey = useUTMsessionStorageState()
  const isAnalyticsV2Available = useIsAnalyticsV2Avail()

  useEffect(() => {
    const referrer = getQueryParam('referrer')
    if (referrer === 'whatsapp-business') {
      setWhatsappBusiness(true)
      setOrderSource('chat')
    }
    if (referrer === 'messenger') {
      setOrderSource('messenger')
    }
  }, [])

  useEffect(() => {
    const url = getQueryParam('msn_return_url')
    if (url) setMessengerReturnURL(url)
  }, [])

  useEffect(() => {
    const order_source = getQueryParam('order_source')
    if (order_source) setOrderSource(order_source)

    Object.entries(UTMS).forEach(([key, value]) => {
      const param = getQueryParam(key)
      setUTMByKey(param, value)
    })

    const webview = getQueryParam('webview')
    if (webview) setWebview(webview)
  }, [])

  const websiteData = <WebsiteData websiteId={websiteId} />
  if (isAnalyticsV2Available) {
    return (
      <PageMeta>
        {props.children}
        {websiteData}
      </PageMeta>
    )
  }
  return (
    <PageMeta>
      {props.children}
      {websiteData}
    </PageMeta>
  )
}
