import React, {useEffect, useState} from 'react'
import dynamic from 'next/dynamic'

const NoSsr = props => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null
  return <React.Fragment>{props.children}</React.Fragment> 
}

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
})
