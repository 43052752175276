import {useAuthLoginURL} from '@helpers/auth/loginViaAuth'

import useUserId from './useUserId'

export default function useForceLogin() {
  const userId = useUserId()
  const loginViaAuth = useAuthLoginURL({
    fallbackURL: '/'
  })

  if (!userId) {
    window.location.href = loginViaAuth

    throw new Promise<void>(() => {
      // never resolvers because we are redirecting
    })
  }
}
