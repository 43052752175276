export type BrowseTaxonomies = {
  addressModalViewed: {
    actionLocation?: string
    websiteURL?: string
    websiteId?: string
    websiteUrl?: string
    menulayoutDesign?: string
    productCardDesign?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  addressSubmitted: {
    selectionType?: string
    isPinAdjusted?: boolean
    deliveryType?: string
    isAddressCoverage?: boolean
    isStoreOpen?: boolean
    errorMessage?: string
    actionLocation?: string
    menuId?: string
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
  }
  addressStartViewed: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
  }
  authSuccessful: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
  }
  deliveryTypeScheduled: {
    scheduleAction?: string
    scheduleDate?: string
    scheduleTime?: string
    isStoreOpen?: boolean
    isOutOfStock?: boolean
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
    actionLocation?: string
  }
  loginStarted: {
    actionLocation?: string
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  orderPageViewed: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    menulayoutDesign?: string
    productCardDesign?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  productSelected: {
    categoryId?: string
    categoryName?: string
    productId?: string
    productName?: string
    menuId?: string
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
    actionLocation?: string
  }
  upsellingViewed: {
    categoryId?: string
    categoryName?: string
    productId?: string
    productName?: string
    menuId?: string
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
    itemsAmount?: number
    productsIds?: string
  }
  bannerViewed: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
    firstSlideLink?: string
  }
  bannerClicked: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
    clickedSlideLink?: string
  }
}

export const BROWSE_EVENTS = Object.freeze({
  addressModalViewed: 'address-modal-viewed',
  addressSubmitted: 'address-submitted',
  addressStartViewed: 'address-start-viewed',
  authSuccessful: 'auth-successful',
  deliveryTypeScheduled: 'delivery-type-scheduled',
  loginStarted: 'login-started',
  orderPageViewed: 'order-page-viewed',
  productSelected: 'product-selected',
  upsellingViewed: 'upselling-viewed',
  bannerViewed: 'banner-viewed',
  bannerClicked: 'banner-clicked'
})
