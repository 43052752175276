import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import useInitialData from '@page-components/Layout/useInitialData'
import useLayout from '@page-components/Order/useLayout'

import useWebsiteId from './useWebsiteId'

export interface UseEventsBaseProperties {
  storeId: string
  websiteId: string
  websiteUrl: string
  countryCode: string
  deviceType: string
}

export default function useEventsBaseProperties(): UseEventsBaseProperties {
  const websiteId = useWebsiteId()
  const initialData = useInitialData()
  const prefs = useUserPreferencesPartial() 
  const layout = useLayout()

  const properties = {
    storeId: prefs?.data?.userPreferences?.storeId ?? '',
    websiteId,
    websiteUrl: '',
    countryCode: '',
    deviceType: layout === 'mobile' ? 'mobile' : 'desktop'
  }

  if (!initialData?.website) return properties

  const website = initialData.website
  properties.websiteUrl = website.baseURL
  properties.countryCode = website.countryCode

  return properties
}
