import {EVENTS} from '@helpers/analytics/events'
import {BrowseTaxonomies} from '@helpers/analytics/events/browse'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useWebsiteId from '@hooks/useWebsiteId'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import getJWTSavedData from '@providers/apollo/getJWTSavedData'
import onSessionSaved from '@providers/apollo/onSessionSaved'
import getEnv from '@providers/getEnv'
import isEmpty from 'lodash/isEmpty'
import {useRouter} from 'next/router'

const getBaseURL = (websiteId: string, returnPath: string, fallbackURL?: string) => {
  const baseURL = {
    local: 'http://auth.internal:5140',
    dev: 'https://auth.bejusto.com',
    prod: 'https://auth.getjusto.com',
  }[getEnv()]
  const domain = encodeURIComponent(window?.location?.host)
  const e = encodeURIComponent
  const params = {
    domain,
    return: returnPath,
    fallback: fallbackURL,
  }
  const url = `${baseURL}/${websiteId}?${Object.entries(params)
    .map(([k, v]) => (v ? `${e(k)}=${e(v)}` : null))
    .filter(Boolean)
    .join('&')}`
  return url
}

export function useAuthLoginURL(options?: {fallbackURL?: string}) {
  const websiteId = useWebsiteId()
  const router = useRouter()
  return getBaseURL(websiteId, router.asPath, options?.fallbackURL)
}

const popupCenter = (url: string, title: string, w: number, h: number) => {
  // Fixes dual-screen position | Most browsers | Firefox
  const dualScreenLeft = window.screenLeft ?? window.screenX
  const dualScreenTop = window.screenTop ?? window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  )

  if (window.focus) newWindow.focus()
  return newWindow
}

export function useLoginViaAuthWindow(actionLocation = '') {
  const websiteId = useWebsiteId()
  const router = useRouter()
  const eventBaseProperties = useEventsBaseProperties()
  const analyticsV2 = useLazyAnalyticsV2()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()

  return () => {
    const url = getBaseURL(
      websiteId,
      `/auth/token/ready?return=${encodeURIComponent(router.asPath)}`,
    )
    if (!isAnalyticsV2Avail) {
      sendEvent<BrowseTaxonomies['loginStarted']>(EVENTS.browse.loginStarted, {
        actionLocation,
        ...eventBaseProperties,
      })
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.loginStarted, {
        actionLocation,
      })
    }
    // open url as a login dialog
    const loginWindow = popupCenter(url, 'Login', 350, 450)
    // listen for window closed
    const interval = setInterval(async () => {
      if (loginWindow.closed) {
        clearInterval(interval)
        const tokenData = getJWTSavedData()
        if (isEmpty(tokenData)) {
          // user closed the window without logging in
        } else {
          if (!isAnalyticsV2Avail) {
            sendEvent<BrowseTaxonomies['authSuccessful']>(
              EVENTS.browse.authSuccessful,
              eventBaseProperties,
            )
          } else {
            analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.authSuccessful, {
              actionLocation,
            })
          }
          await onSessionSaved(tokenData)
        }
      }
    }, 50)
  }
}
