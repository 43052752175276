import React, {useContext} from 'react'
import PageMetaContext from '@helpers/websites/PageMetaContext'
import useInitialData from '@page-components/Layout/useInitialData'
import Head from 'next/head'

export default function Styles() {
  const {website, seo: config} = useInitialData()

  const {subtitle, description, image, schema} = useContext(PageMetaContext)

  if (!website) return null

  const seo = {
    title: website.name,
    description: `Pide ${website.name}`,
    ...config.seo
  }
  const pageTitle = subtitle ? `${subtitle} - ${seo.title}` : seo.title
  const pageDescription = description || seo.description
  const pageImage = image || seo.image
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={website.baseURL} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      {pageImage && (
        <meta
          property="og:image"
          content={typeof pageImage === 'string' ? pageImage : pageImage.url}
        />
      )}
      {seo.favicon && <link rel="shortcut icon" href={seo.favicon.url} />}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{__html: schema ? JSON.stringify(schema, null, 2) : ''}}
      />
    </Head>
  )
}
