import React, {Suspense} from 'react'
import ClientSuspense from '@components/ClientSuspense'
import LayoutComponent from '@components/Layout'
import SafeSuspense from '@components/SafeSuspense'
import getCurrentMainDomain from '@helpers/misc/domain/getCurrentMainDomain'
import getContrastColor from '@helpers/misc/getContrastColor'
import CookiesBanner from '@page-components/CookiesBanner'
import LayoutColorThemeContext from '@providers/LayoutColorThemeContext'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import useInitialData from './useInitialData'
import {WebsiteProps} from './Website'

const NoWebsite = dynamic(() => import('@page-components/NoWebsite'))

export interface LayoutProps {
  children: React.ReactNode
  forceLightTheme?: boolean
  darkTheme?: 'dark' | 'darkv2'
  LayoutComponent: React.ComponentType<WebsiteProps>
}

export default function AbstractLayout(props: LayoutProps) {
  const {
    forceLightTheme = false,
    LayoutComponent: LayoutImplementation,
    darkTheme = 'dark'
  } = props

  const initialData = useInitialData()

  if (!initialData) {
    return <NoWebsite />
  }

  const isDarkBackground =
    getContrastColor(initialData.design?.design?.backgroundColor || '#ffffff') === '#ffffff'

  const isInMainDomain = !!getCurrentMainDomain()

  return (
    <SafeSuspense
      fallback={
        <div className="initialLoadingContainer">
          <div className="initialLoadingItem" />
        </div>
      }>
      <div className={isDarkBackground && !forceLightTheme ? 'dark' : ''}>
        {isInMainDomain ? (
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
        ) : null}
        <LayoutColorThemeContext.Provider
          value={forceLightTheme ? 'lightOverwritten' : isDarkBackground ? darkTheme : 'light'}>
          <Suspense
            fallback={
              <div className="initialLoadingContainer">
                <div className="initialLoadingItem" />
              </div>
            }>
            <LayoutComponent>
              <LayoutImplementation website={initialData?.website}>
                {props.children}
              </LayoutImplementation>
            </LayoutComponent>
          </Suspense>
        </LayoutColorThemeContext.Provider>
        <ClientSuspense fallback={null}>
          <CookiesBanner website={initialData.website} />
        </ClientSuspense>
      </div>
    </SafeSuspense>
  )
}
