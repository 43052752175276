export type PurchaseTaxonomies = {
  checkoutPageViewed: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    cartItemsAmount?: number
    productAmount?: number
    subTotalPrice?: number
    isTipAccepted?: number
    deliveryAmountToPay?: number
    amountToPay?: number
    isScheduledOrder?: boolean
    timeLabel?: string
    minDeliveryTime?: string
    maxDeliveryTime?: string
    currentPreparationDuration?: string
    currentDeliveryDuration?: string
    availablePaymentTypes?: string[]
    countryCode?: string
    deviceType?: string
  }
  continueToCheckout: {
    subTotalPrice?: number
    cartitemsAmount?: number
    productAmount?: number
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  couponAdded: {
    couponDiscount?: number
    couponCode?: string
    couponName?: string
    couponId?: string
    errorMessage?: string
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  productAdded: {
    categoryId?: string
    categoryName?: string
    productName?: string
    productId?: string
    productDescription?: string
    productAmount?: number
    fullPrice?: number
    productDiscount?: number
    productTotalPrice?: number
    amountRequiredModifiers?: number
    amountOptionalModifiers?: number
    selectedOptionalModifiers?: number
    actionLocation?: string
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  cartPageViewed: {
    cartItemsAmount?: number
    productAmount?: number
    subTotalPrice?: number
    isScheduledOrder?: boolean
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  productEdited: {
    productEditAction?: string
    productId?: string
    productAmount?: number
    isAmountEdited?: boolean
    isOutOfStock?: boolean
    websiteURL?: string
    websiteId?: string
    storeId?: string
    userId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
    actionLocation?: string
  }
  tipAmountChanged: {
    suggestedTip?: number
    selectedTip?: number
    websiteURL?: string
    websiteId?: string
    storeId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  trackingPageViewed: {
    orderId?: string
    cartItemsAmount?: number
    productAmount?: number
    subTotalPrice?: number
    couponAmount?: number
    couponCode?: string
    couponName?: string
    couponId?: string
    loyaltyAmount?: number
    tipAmount?: number
    deliveryAmountToPay?: number
    amountToPay?: number
    isScheduledOrder?: boolean
    scheduleDate?: string
    scheduleTime?: string
    timeLabel?: string
    minDeliveryTime?: string
    maxDeliveryTime?: string
    currentPreparationDuration?: string
    currentDeliveryDuration?: string
    payStatus?: string
    payMethod?: string
    cardType?: string
    cardId?: string
    websiteURL?: string
    websiteId?: string
    storeId?: string
    userId?: string
    menuId?: string
    countryCode?: string
    deviceType?: string
  }
  webOrderConfirmed: {
    storeId?: string
    websiteId?: string
    websiteUrl?: string
    countryCode?: string
    deviceType?: string
    payMethod?: string
    payStatus?: string
    cardType?: string
    orderId?: string
  }
}

export const PURCHASE_EVENTS = Object.freeze({
  cartPageViewed: 'cart-page-viewed',
  checkoutPageViewed: 'checkout-page-viewed',
  continueToCheckout: 'continue-to-checkout',
  couponAdded: 'coupon-added',
  productAdded: 'product-added',
  productEdited: 'product-edited',
  tipAmountChanged: 'tip-amount-changed',
  webOrderConfirmed: 'web-order-confirmed',
  trackingPageViewed: 'tracking-page-viewed'
})
