import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

import SetLocale from './SetLocale'

export default function Locale() {
  const {website} = useInitialData()

  return <SetLocale locale={website.country.locale} />
}
