import isServerSide from '@helpers/misc/isServerSide'
import {NextRouter} from 'next/router'

export default function getPathname(router: NextRouter) {
  return router.pathname.replace('/_sites/[viewport]/[hostname]', '')
}

export function getPath(router: NextRouter) {
  const regex = /\/_sites\/[^\/]+\/[^\/]+\/*(.*)/
  const match = router.asPath.match(regex)
  if (isServerSide() && match) {
    return match[1] ? `/${match[1]}` : '/'
  }
  return router.asPath
}
