import {BROWSE_EVENTS, BrowseTaxonomies} from './browse'
import {PAY_EVENTS, PayTaxonomies} from './pay'
import {PURCHASE_EVENTS, PurchaseTaxonomies} from './purchase'

// eslint-disable-next-line @typescript-eslint/ban-types
export type AllTaxonomies = PayTaxonomies | BrowseTaxonomies | PurchaseTaxonomies | {} // Add other taxonomies here

export const EVENTS = {
  pay: PAY_EVENTS,
  browse: BROWSE_EVENTS,
  purchase: PURCHASE_EVENTS
}

export const ACTION_LOCATIONS = {
  browse: {'/order': 'order', '/order/category/[categoryId]': 'order'},
  purchase: {'/order': 'order', '/order/category/[categoryId]': 'order', '/checkout': 'upselling'},
  selectProduct: {'/order': 'order', '/order/category/[categoryId]': 'order', '/checkout': 'upselling'},
  editProduct: {
    '/order': 'order',
    '/order/category/[categoryId]': 'order',
    '/checkout': 'checkout'
  }
}
