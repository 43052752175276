export default function getInputStyles({
  backgroundColor,
  textColor,
  lighterBackgroundColor,
  backgroundBorderColor,
  color,
  isDarkBackground,
  inputBorderColor,
  descriptionTextColor
}) {
  if (!isDarkBackground) return ''

  return (
    <style jsx global>{`
      .os-input-text {
        background: ${backgroundBorderColor};
        color: ${textColor};
        border-color: ${inputBorderColor};
      }

      .os-input-text::placeholder {
        color: ${descriptionTextColor};
      }

      .os-input-text:focus {
        border-color: ${color};
      }

      .orion-select__control * {
        color: ${textColor};
      }

      .orion-select__control .css-1pndypt-Input {
        color: ${textColor} !important;
      }

      div.orion-select__option {
        color: ${lighterBackgroundColor};
      }

      .orion-select__option > div > div {
        color: ${lighterBackgroundColor};
      }

      .orion-select__single-value {
        color: ${textColor} !important;
      }

      .orion-select__single-value * {
        color: ${textColor} !important;
      }

      div.orion-select__control {
        background: ${backgroundBorderColor};
        border-color: #333;
      }

      .orion-select__control--is-focused {
        border-color: ${color} !imporant;
      }

      .orion-select__option--is-selected > div > div {
        color: ${textColor};
      }
      div.orion-select__option--is-selected {
        color: ${textColor};
      }
    `}</style>
  )
}
