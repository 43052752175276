import {useEffect} from 'react'
import isScrapping from '@helpers/misc/isScrapping'
import useInitialData from '@page-components/Layout/useInitialData'

export default function SendPulseWebPushIntegration() {
  const {integrations: config} = useInitialData()

  useEffect(() => {
    if (
      !isScrapping &&
      config &&
      config.integrations &&
      config.integrations.sendpulse &&
      config.integrations.sendpulse.apiKey
    ) {
      const script = document.createElement('script')
      const url = `//web.webpushs.com/js/push/${config.integrations.sendpulse.apiKey}.js`

      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [config])

  return null
}
