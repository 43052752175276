import isServerSide from '@helpers/misc/isServerSide'

// Using any type instead of react-facebook-pixel types because
// react-facebook-pixel is auto-fetching the facebook pixel script even when it's not used
export interface ReactPixel {
  pageView: any
  track: any
  trackCustom: any
  trackSingle: any
  trackSingleCustom: any
  fbq: any
  revokeConsent: any
  grantConsent: any
}

let pixel = null
const pixelMap = {}

export function initReactPixel(
  pixelId: string,
  pixelUser?: any,
  additionalParams?: {cookiesAccepted: boolean}
) {
  if (isServerSide() || !additionalParams?.cookiesAccepted) return null

  import('react-facebook-pixel')
    .then(x => x.default)
    .then(rpx => {
      if (pixelId && !pixelMap[pixelId]) {
        pixel = rpx
        rpx.init(pixelId, pixelUser)
        pixelMap[pixelId]
        rpx.pageView()
      }
    })
}

export default function getReactPixel(): ReactPixel | null {
  return pixel
}
