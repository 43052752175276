import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'
import dynamic from 'next/dynamic'

export default function GoogleAnalytics() {
  const {integrations: config} = useInitialData()

  if (!config?.integrations?.googleAnalyticsId && !config?.integrations?.googleAnalyticsV4Id) {
    return null
  }

  const GA = dynamic(() => import('./GA'))

  return <GA />
}
