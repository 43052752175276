import ClientSuspense from '@components/ClientSuspense'
import useCookiesAccepted from '@hooks/useCookiesAccepted'
import {useEffect} from 'react'
import useLazyAnalyticsV2 from '../hooks/useLazyAnalyticsV2'

function InitAnalyticsInner() {
  const cookies = useCookiesAccepted()
  const analyticsV2 = useLazyAnalyticsV2()

  useEffect(() => {
    analyticsV2.initializeAll()
  }, [cookies])

  return null
}

export default function InitAnalytics() {
  return (
    <ClientSuspense fallback={null}>
      <InitAnalyticsInner />
    </ClientSuspense>
  )
}
