import React from 'react'
import useInitialData from '@page-components/Layout/useInitialData'
import dynamic from 'next/dynamic'

export default function HighlightIntegration() {
  const {integrations: config} = useInitialData() as any

  if (!config?.integrations?.highlightId) return null

  const Highlight = dynamic(() => import('./Highlight'))

  return <Highlight />
}
