import React from 'react'
import Image from '@components/Image'
import isServerSide from '@helpers/misc/isServerSide'
import useInitialData from '@page-components/Layout/useInitialData'

import styles from './styles.module.css'

export default function WhatsappChat() {
  const {integrations: config} = useInitialData()

  if (!config) return null
  if (!config.integrations) return null
  if (!config.integrations.supportWhatsappNumber) return null

  const number = config.integrations.supportWhatsappNumber.replace('+', '')

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (isServerSide()) return
        window.open(`https://wa.me/${number}`)
      }}>
      <Image src="/images/icons/whatsapp-logo.svg" width={30} height={30} alt="WhatsApp Logo" />
    </div>
  )
}
