/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import isServerSide from '@helpers/misc/isServerSide'

declare const window: {
  Intercom: any
}

export default async function LoadIntercom() {
  if (isServerSide()) return
  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    // ic('update', intercomSettings)
  } else {
    const d = document
    var i = function () {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'c' does not exist on type '() => void'.
      i.c(arguments)
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'q' does not exist on type '() => void'.
    i.q = []
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'c' does not exist on type '() => void'.
    i.c = function (args) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'q' does not exist on type '() => void'.
      i.q.push(args)
    }
    w.Intercom = i

    const s = d.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = 'https://widget.intercom.io/widget/lgrw58ef'
    const x = d.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s, x)
  }
}
