import logger from '@helpers/logger'

import {AllTaxonomies} from './events'

const sendEventToAnalytics = <Taxonomy extends AllTaxonomies>(
  eventType: string,
  properties?: Taxonomy
) => {
  import('@amplitude/analytics-browser').then(amplitude => {
    try {
      amplitude.track(eventType, {...properties})
    } catch (error) {
      logger.debug('Error sending event to Amplitude', error)
    }
  })
}

export const sendEvent = async <Taxonomy extends AllTaxonomies>(
  eventType: string,
  eventProperties: Taxonomy
) => {
  try {
    const obj = JSON.parse(JSON.stringify(eventProperties))
    sendEventToAnalytics(eventType, obj)
  } catch (e) {
    logger.error({e})
  }
}
