import React from 'react'

export default React.createContext<{
  subtitle?: string
  description?: string
  image?: string
  schema?: string
  setSubtitle: (string?) => void
  setDescription: (string?) => void
  setImage: (any?) => void
  setSchema: (any?) => void
}>({
  setSubtitle: () => {},
  setDescription: () => {},
  setImage: () => {},
  setSchema: () => {}
})
