import {useContext} from 'react'
import LayoutColorThemeContext from '@providers/LayoutColorThemeContext'

/**
 * Light: white background, dark text (configured so by the brand)
 * Dark: dark background, white text (configured so by the brand)
 * LightOverwritten: white background, dark text (forced by some of our pages like Checkout V3 which does not have a dark mode)
 * @returns 'light' | 'dark' | 'darkv2' | 'lightOverwritten' | null
 */
export default function useLayoutColorTheme() {
  return useContext(LayoutColorThemeContext)
}
