import parse from 'color-parse'

// https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

function getContrast(rgb1, rgb2) {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2])
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2])
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return (brightest + 0.05) / (darkest + 0.05)
}

/**
 * @readonly
 * @enum {numbert}
 */
export const CONTRAST_VALUES = Object.freeze({
  GOOD: 2,
  MEDIUM: 1,
  BAD: 0
})

/**
 *
 * @param {string} color1
 * @param {string} color2
 * @returns {string} - 'CONTRAST_VALUES'
 */
export function isContrastGood(color1, color2) {
  if (!(color1 && color2)) {
    return
  }

  const rgb1 = parse(color1).values
  const rgb2 = parse(color2).values
  const contrast = getContrast(rgb1, rgb2)

  if (contrast < 3) {
    return CONTRAST_VALUES.BAD
  }

  if (contrast < 4.5) {
    return CONTRAST_VALUES.MEDIUM
  }

  return CONTRAST_VALUES.GOOD
}
