import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import InitAnalytics from '@page-components/AnalyticsV2/tracking-components/InitAnalytics'

import WithUserChats from './WithUserChats/UserChat'
import FacebookChat from './FacebookChat'
import FidelityToolsIntegration from './FidelityToolsIntegration'
import GA from './GA'
import GoogleTagManager from './GoogleTagManager'
import HighlightIntegration from './HighlightIntegration'
import IndigitallWebPushIntegration from './IndigitallWebPushIntegration'
import InsidersIntegration from './InsidersIntegration'
import JivoChat from './JivoChat'
import Locale from './Locale'
import LogRocketIntegration from './LogRocket'
import Meta from './Meta'
import SendPulseWebPushIntegration from './SendPulseWebPushIntegration'
import Styles from './Styles'
import WhatsappChat from './WhatsappChat'
import WithAuth from './WithAuth'

export default function WebsiteLayout(props: {onlyMeta?: boolean; websiteId?: string}) {
  const {onlyMeta} = props
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const defaultComponent = (
    <>
      <WithAuth />
      <Meta />
      <Locale />
    </>
  )

  if (onlyMeta) {
    return defaultComponent
  }

  return (
    <>
      <Styles />
      {defaultComponent}
      <ClientSuspense fallback={null}>
        {isAnalyticsV2Avail && <InitAnalytics />}
        <WithUserChats />
        <JivoChat />
        <FacebookChat />
        <WhatsappChat />
        {!isAnalyticsV2Avail && <GA />}
        {!isAnalyticsV2Avail && <GoogleTagManager />}
        <LogRocketIntegration />
        <SendPulseWebPushIntegration />
        <IndigitallWebPushIntegration />
        <FidelityToolsIntegration />
        <InsidersIntegration />
        <HighlightIntegration />
      </ClientSuspense>
    </>
  )
}
