import {useEffect} from 'react'
import {useOnEvent} from 'react-app-events'
import {amplitudeDev, amplitudeProd} from '@helpers/events/config'
import logger from '@helpers/logger'
import useCookiesAccepted from '@hooks/useCookiesAccepted'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import getEnv from '@providers/getEnv'

import useAmplitude from './useAmplitude'

interface Session {
  jwt?: string
  refresh?: string
  userId?: string
  email?: string
  firstName?: string
  fullName?: string
  roles?: string[]
}

export function useInitAnalytics(omit = false) {
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const cookies = useCookiesAccepted()
  const availableToUser = useAmplitude()
  if (!['prod', 'dev'].includes(getEnv())) {
    omit = true
  }

  useEffect(() => {
    if (isAnalyticsV2Avail || omit || !cookies || !availableToUser) return

    import('@amplitude/analytics-browser').then(({init, Types}) => {
      const isProd = getEnv() === 'prod'
      const amplitudeApiKey = isProd ? amplitudeProd : amplitudeDev

      init(amplitudeApiKey, {
        logLevel: isProd ? Types.LogLevel.Error : Types.LogLevel.Warn,
        defaultTracking: {
          attribution: true,
          pageViews: {
            trackOn: 'attribution',
            eventType: 'detected-referral'
          },
          sessions: false,
          formInteractions: false,
          fileDownloads: false
        }
      })
    })
  }, [omit, cookies, availableToUser])

  useOnEvent('saveAmplitudeSession', async (newSession: Session) => {
    if (omit || !cookies || !availableToUser) return

    import('@amplitude/analytics-browser').then(amplitude => {
      try {
        amplitude.setUserId(newSession?.userId ?? null)
        logger.debug('configured Amplitude user id', newSession?.userId ?? null)
      } catch (error) {
        logger.warn(error)
      }
    })
  })

  return null
}
