import {useEffect} from 'react'
import isScrapping from '@helpers/misc/isScrapping'
import useInitialData from '@page-components/Layout/useInitialData'

export default function InsidersIntegration() {
  const {integrations: config} = useInitialData() as any

  useEffect(() => {
    if (!isScrapping && config?.integrations?.insidersUrl) {
      const script = document.createElement('script')
      const url = config.integrations.insidersUrl
      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [config])

  return null
}
